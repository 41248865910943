/* 
Text color: #08fdd8
Backgroud color: #1d1d1d
Purple: #69369e
rgb(210, 108, 213) - #d26cd5
yellow: #FFAC00
*/

html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
:root {
  --main-color: #ffac00;
  --text-color: #cccccc;
  --background-color: #1d1d1d;
  --white: #fff;
  --slate: #ababab;
  --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  --transform: translate(0, -5px);
  --font-xxs: 12px;
  --font-xs: 13px;
  --font-s: 15px;
  --font-lg: 18px;
  --font-xl: 20px;
  --font-xxl: 22px;
  --font-abheading: 25px;
  --main-filter: invert(65%) sepia(80%) saturate(1540%) hue-rotate(359deg)
    brightness(101%) contrast(105%);
  --gray-filter: invert(87%) sepia(1%) saturate(0%) hue-rotate(134deg)
    brightness(97%) contrast(88%);
}

.App {
  background-color: var(--background-color);
  min-height: 100vh;
  color: #cccccc;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  align-items: center;
}

.intro-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;
  margin: 0px auto;
  padding: 150px;
  padding-right: 0px;
  padding-top: 0px;
  min-height: 100vh;
  z-index: 10;
}

.intro-description {
  color: var(--slate);
  font-size: 20px;
}

.topbar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0px;
  padding: 0px 50px;
  text-align: right;
  box-shadow: 0px 10px 30px -10px var(--main-color);
  counter-reset: item;
}

.topbar ol {
  list-style: none;
  z-index: 10;
}

.topbar li {
  padding: 10px;
  float: left;
  font-size: 13px;
}

.topbar li a {
  text-decoration: none;
  color: var(--text-color);
}

.topbar li a:visited {
  color: var(--text-color);
}

.topbar li a:hover {
  cursor: pointer;
  color: var(--main-color);
}

.topbar li a:before {
  counter-increment: item;
  content: "0" counter(item) ".";
  margin-right: 5px;
  text-align: right;
  font-size: var(--font-xs);
  color: var(--main-color);
}

.styled-links {
  display: flex;
  align-items: center;
}

.btn {
  margin-top: 8px;
  -webkit-border-radius: 10;
  -moz-border-radius: 10;
  border-radius: 10px;
  font-family: Arial;
  color: var(--main-color);
  font-size: 13px;
  background: #1d1d1d;
  padding: 12px 16px;
  border: solid var(--main-color) 2px;
  text-decoration: none;
  z-index: 10;
}

.btn:hover {
  background-color: rgb(255, 172, 0, 0.3);
  text-decoration: none;
  cursor: pointer;
  color: var(--white);
}

/* *************** Header Animation 1 ***************** */

.animated-header {
  display: flex;
  margin: 0;
  font-size: 35px;
}

.intro-hey {
  display: flex;
  padding-right: 10px;
}

.intro-my {
  display: flex;
  padding-right: 10px;
}

.intro-name {
  display: flex;
  padding-right: 10px;
}

.intro-is {
  display: flex;
  padding-right: 10px;
}

/* *************** Header Animation 1 - End ***************** */

/* *************** Header Animation 2 ***************** */

.animated-header2 {
  display: flex;
  margin: 0;
  font-size: 60px;
}

.intro-justin {
  display: flex;
  padding-right: 15px;
}

.intro-stephens {
  display: flex;
  padding-right: 10px;
}

/* *************** Header Animation 2 - End ***************** */

/* *************** Header Animation 3 ***************** */

.animated-header3 {
  display: flex;
  margin: 0;
  font-size: 60px;
}

.intro-Im {
  display: flex;
  padding-right: 15px;
}

.intro-A {
  display: flex;
  padding-right: 15px;
}

.intro-Web3 {
  display: flex;
  padding-right: 15px;
}

.intro-Developer {
  display: flex;
  padding-right: 10px;
}

/* *************** Header Animation 3 - End ***************** */

.intro-rubberBand:hover {
  animation: rubberBand;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  color: var(--main-color);
}

/****************** Sidenav  ***********************/

.sidenav {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100%; */
  width: 30;
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  background-color: #1d1d1d;
}

.sidenav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 10px;
  list-style: none;
}

.sidenav li {
  padding: 10px;
  padding-bottom: 0px;
}

.sidenav:after {
  content: "";
  background-color: white;
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
}

.sidenav-right {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  width: 30;
  position: fixed;
  z-index: 10;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  background-color: var(--background-color);
}

.sidenav-right:after {
  content: "";
  background-color: white;
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
}

.sidenav-right a {
  text-decoration: none;
  color: #cccccc;
  writing-mode: vertical-rl;
  padding: 10px;
  font-size: 20px;
}

.sidenav-right a:hover {
  color: var(--main-color);
  transition: var(--transition);
  transform: var(--transform);
}

/******************* Sidenav Links  ********************/

.github-logo {
  filter: var(--gray-filter);
}

.github-logo:hover {
  filter: var(--main-filter);
  transition: var(--transition);
  transform: var(--transform);
}

.LinkedIn-Logo {
  filter: var(--gray-filter);
}

.LinkedIn-Logo:hover {
  filter: var(--main-filter);
  transition: var(--transition);
  transform: var(--transform);
}

.Instagram-Logo {
  filter: var(--gray-filter);
}

.Instagram-Logo:hover {
  filter: var(--main-filter);
  transition: var(--transition);
  transform: var(--transform);
}

/******************* Sidenav Links - End ********************/

@media screen and (max-width: 1080px) {
  .sidenav {
    margin-right: 5%;
  }
}

/****************** Sidenav - End ***********************/

/****************** About Me *************************/

.about-section {
  max-width: 1200px;
  margin: 0px auto;
  padding: 100px 0px;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 50px;
}

.about-header {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  white-space: nowrap;
  font-size: var(--font-abheading);
  margin: 10px 0px 40px;
  counter-reset: heading;
}

.about-header:before {
  position: relative;
  top: 5px;
  counter-increment: heading;
  content: "0" counter(heading) ".";
  margin-right: 10px;
  margin-bottom: 7px;
  color: var(--main-color);
  font-size: var(--font-abheading);
  font-weight: 400;
}

.about-header:after {
  content: "";
  display: block;
  position: relative;
  top: 5px;
  width: 75%;
  height: 1px;
  margin-left: 20px;
  background-color: var(--text-color);
}

.about-description {
  margin: 0px 0px 15px;
  float: left;
}

.about-paragraph {
  margin: 0px 0px 15px;
  text-align: left;
  font-size: var(--font-xl);
  color: var(--white);
}

.chart {
  margin-bottom: 2rem;
}

.chart span {
  font-size: 18px;
  margin-bottom: 7px;
  display: block;
}

.chart footer {
  background-color: white;
  height: 2px;
}

.skills-list {
  margin-top: auto;
  margin-bottom: auto;
}

.first-footer div {
  height: 100%;
  width: 95%;
  background-color: #ffac00;
}

.second-footer div {
  height: 100%;
  width: 90%;
  background-color: #cf8400;
}

.third-footer div {
  height: 100%;
  width: 80%;
  background-color: #a05e00;
}

.fourth-footer div {
  height: 100%;
  width: 93%;
  background-color: #753900;
}

.fifth-footer div {
  height: 100%;
  width: 98%;
  background-color: #501600;
}
/****************** About Me - End *************************/

/****************** Experience **************************/

.experience-section {
  max-width: 900px;
  margin: 0px auto;
  padding: 100px 0px;
}

.experience-header {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  white-space: nowrap;
  font-size: var(--font-abheading);
  margin: 10px 0px 40px;
}

.experience-header:before {
  position: relative;
  top: 5px;
  counter-increment: heading 2;
  content: "0" counter(heading) ".";
  margin-right: 10px;
  margin-bottom: 7px;
  color: var(--main-color);
  font-size: var(--font-abheading);
  font-weight: 400;
}

.experience-header:after {
  content: "";
  display: block;
  position: relative;
  top: 5px;
  width: 75%;
  height: 1px;
  margin-left: 20px;
  background-color: var(--text-color);
}

.experience-inner {
  display: flex;
  min-height: 340px;
}

.react-tabs {
  display: flex;
  min-height: 340px;
}

/************************ Particle Start **********************/

#tsparticles {
  position: absolute;
  bottom: 0;
  /* top: 0; */
  left: 0;
  right: 0;
  width: 100%;
  height: 86%;
  z-index: 0;
  background-color: #1d1d1d;
}

.particle-text {
  text-align: center;
  font-size: 50px;
  width: 100%;
  z-index: 10;
  bottom: -200;
  padding-top: 50px;
  color: green;
}

.particle-container {
  text-align: center;
  position: absolute;
  top: 100;
  height: 500px;
  width: 100%;
  color: pink;
}

.particle-background {
  position: absolute;
  top: 100;
  height: 100%;
  position: absolute;
}

/************************ Particle End **********************/

.experience-tablist {
  position: relative;
  z-index: 3;
  width: max-content;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  list-style: none;
}

.tablist-item {
  text-decoration: none;
  text-decoration-skip-ink: auto;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 0px 20px 2px;
  border: none;
  border-left: 2px solid var(--white);
  background-color: transparent;
  color: var(--white);
  font-size: var(--font-xs);
  text-align: left;
  white-space: nowrap;
}

.tab-selected {
  color: var(--main-color);
  border: none;
  border-left: 2px solid var(--main-color);
}

.tablist-item:hover {
  color: var(--main-color);
  background-color: #383b3f;
  cursor: pointer;
}

.tab-panels {
  position: relative;
  width: 100%;
  margin-left: 20px;
}

.project-title {
  margin-bottom: 2px;
  margin-top: 0px;
  font-size: var(--font-xxl);
}

.project-title-link {
  margin-bottom: 2px;
  margin-top: 0px;
  margin-left: 5px;
  font-size: var(--font-xxl);
  color: var(--main-color);
  display: inline-block;
}

.project-title-link a {
  margin-left: 5px;
  color: var(--main-color);
  text-decoration: none;
}

.project-title-link a:hover {
  text-decoration: underline;
}

.project-title a img {
  align-items: center;
  position: relative;
  margin-left: 10px;
  margin-bottom: -3px;
  filter: var(--main-filter);
}

.project-time {
  margin-bottom: 25px;
  margin-top: 0px;
  font-size: var(--font-xs);
}

.project-category {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: var(--font-lg);
}

.project-description {
  font-size: var(--font-lg);
}

.project-details ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  font-size: var(--font-lg);
}

.project-details ul li:before {
  content: ">";
  position: absolute;
  left: 0px;
  color: var(--main-color);
}

.project-details ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.project-tech-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 25px 20px 10px;
  padding: 0px;
  list-style: none;
  font-size: var(--font-s);
}

.project-tech-list li {
  padding-left: 10px;
  color: var(--slate);
}

.project-links {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  color: var(--gray-filter);
}

/****************** Experience - End **************************/

/******************* Other Projects **********************/

.other-projects-section {
  text-align: center;
}

.projects-grid {
  list-style: none;
  padding: 0px;
  margin: 50px 0px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  position: relative;
}

.projects-inner {
  box-shadow: 0 10px 30px -15px var(--main-color);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 100%;
  padding: 2rem 1.75rem;
  border-radius: 4px;
  background-color: var(--background-color);
  transition: var(--transition);
}

/******************* Other Projects - End **********************/

/********************** Contact - Start ***********************/

.contact-section {
  max-width: 900px;
  margin: 0px auto;
  padding: 100px 0px;
  text-align: center;
}

.contact-header {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  white-space: nowrap;
  font-size: var(--font-abheading);
  margin: 10px 0px 40px;
}

.contact-header:before {
  position: relative;
  top: 5px;
  counter-increment: heading 3;
  content: "0" counter(heading) ".";
  margin-right: 10px;
  margin-bottom: 7px;
  color: var(--main-color);
  font-size: var(--font-abheading);
  font-weight: 400;
}

.contact-header:after {
  content: "";
  display: block;
  position: relative;
  top: 5px;
  width: 75%;
  height: 1px;
  margin-left: 20px;
  background-color: var(--text-color);
}

.contact-description {
  padding-right: 100px;
  padding-left: 100px;
  margin: 0px 0px 25px;
  text-align: center;
  font-size: var(--font-xl);
  color: var(--white);
}

/********************** Contact - End ***********************/

/********************** Media Queries **********************/

@media (max-width: 1380px) and (min-width: 900px){
  .about-section {
    margin: 0px auto;
  }
  .experience-section {
    margin: 0px auto;

  }

  .contact-section {
    margin: 0px auto;
  }

  .contact-description {
    padding: 0px;
  }
}

@media (max-width: 900px) {
  .about-section {
    display: block;
  }

  .about-section {
    margin: 0px 50px;
  }
  .experience-section {
    margin: 0px 50px;

  }

  .contact-section {
    margin: 0px 50px;
  }

  .react-tabs {
    display: inline-block;
    margin: 0px auto;
  }
}

@media (max-width: 600px) {
  .intro-section {
    display: inline-block;
    margin: 0px 20px;
    padding: 0px;
    text-align: center;
    align-items: center;
  }

  .animated-header {
    display: block;
  }

  .intro-hey {
    display: inline-block;
  }

  .intro-my {
    display: inline-block;
  }

  .intro-name {
    display: inline-block;
  }

  .intro-is {
    display: inline-block;
  }

  .animated-header2 {
    display: block;
  }

  .intro-justin {
    display: inline-block;
  }

  .intro-stephens {
    display: inline-block;
  }

  .animated-header3 {
    display: block;
  }

  .intro-Im {
    display: inline-block;
  }

  .intro-A {
    display: inline-block;
  }

  .intro-Web3 {
    display: inline-block;
  }

  .intro-Developer {
    display: inline-block;
  }
}

/********************** Media Queries - End **********************/
